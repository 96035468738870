<template>
    <div>
        <!-- 顶部工具条 -->
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label='关键字:'>
                        <el-input v-model='filters.condition' placeholder='考生姓名/手机/身份证' clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item label='单位信息:'>
                        <el-input v-model='filters.unitName' placeholder='请输入单位信息' clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item label="地区:" v-if="isShowAreaSearch">
                        <el-cascader :props="props" v-model="filters.selecAreaArr" clearable></el-cascader>
                    </el-form-item>
	                <el-form-item label="当前项目：" v-if="projectDialog">
		                <el-select v-model='filters.projectId' placeholder='请选择' clearable @clear='clearContent'>
			                <el-option v-for='item in projectList' :key='item.Id' :label='item.ProjectName' :value='item.Id'></el-option>
		                </el-select>
	                </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 表格主体 -->
        <el-table
            v-loading='listLoading'
            :data="tableData"
            border
            style="width: 100%"
            highlight-current-row
            :cell-style="rowClass"
            :header-cell-style="headClass"
            @current-change='currentChange'
        >
            <el-table-column type='index' label='序号' width='80' :index='indexMethod'></el-table-column>
            <el-table-column prop="Name" label="姓名" width='120'></el-table-column>
            <el-table-column prop="Gender" label="性别" width='80'>
                <template slot-scope='scope'>
                    <span v-if='scope.row.Gender == 0'>女</span>
                    <span v-else>男</span>
                </template>
            </el-table-column>
            <el-table-column prop='UnitName' label='社会单位名称' min-width="130"></el-table-column>
            <!-- <el-table-column prop='Province' label='省份'></el-table-column> -->
            <el-table-column prop='City' label='城市' width='100'></el-table-column>
            <el-table-column prop='Region' label='区县' width='110'></el-table-column>
            <el-table-column prop="PhoneNumber" label="手机号"></el-table-column>
            <el-table-column prop="CardNo" label="身份证号" min-width="130"></el-table-column>
            <el-table-column prop="RequestSource" label="注册来源" width='110'></el-table-column>
            <el-table-column prop='HeadPhotoUrl' label='头像证件照' width='100'>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.HeadPhotoUrl" alt="" style='width:120px;height:150px'>
                        <img slot="reference" :src="scope.row.HeadPhotoUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="" label="学员流向" min-width="100">
                <template slot-scope='scope'>
                    <el-button @click="viewStuDirection(scope.row)" type="text">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column prop='CreateTime' label='创建时间' min-width="110"></el-table-column>
            <el-table-column prop='ProjectName' label='当前项目' min-width="110"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.PageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.DataCount"
            class='pageBar'>
        </el-pagination>
        <!-- 编辑 -->
        <el-dialog
            :title="isAddOrEdit?'新增':'编辑'"
            :visible.sync="addFormDialog"
            v-model="addFormDialog"
            @close="dialogCloseFun"
            :close-on-click-modal="false"
        >
            <el-form :model="dialogForm" :rules="dialogFormRules" ref="dialogFormRef" label-width="150px" enctype="multipart/form-data">
                <el-form-item label='姓名:' prop='name'>
                    <el-input v-model='dialogForm.name' clearable></el-input>
                </el-form-item>
                <el-form-item label='手机号:' prop='phone'>
                    <el-input v-model='dialogForm.phone' clearable></el-input>
                </el-form-item>
                <el-form-item label='身份证号:' prop='userCard'>
                    <el-input v-model='dialogForm.userCard' clearable></el-input>
                </el-form-item>
                <el-form-item label='性别:'>
                    <el-select v-model="dialogForm.userSex" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in sexArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label='单位名称:' prop='unitName'>
                    <el-input v-model='dialogForm.unitName' clearable></el-input>
                </el-form-item> -->
                <el-form-item label='单位名称:'>
                    <el-select v-model="dialogForm.socialUnitId" filterable remote :remote-method="searchFun" placeholder="请搜索/选择" clearable class="social">
                        <el-option
                            v-for="item in SocialUnitList"
                            :key="item.Id"
                            :label="item.UnitName"
                            :value="item.Id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='信用代码:' prop='socialCreditNumber'>
                    {{dialogForm.socialCreditNumber}}
                </el-form-item>
                <el-form-item label='通讯地址:' prop='unitAddress'>
                    {{dialogForm.unitAddress}}
                    <!-- <el-input v-model='dialogForm.unitAddress' clearable></el-input> -->
                </el-form-item>
                <el-form-item label='证件照:'>
                    <div style="display:flex;">
                        <div v-if="!imageUrlDataStamp" style="margin-right:20px;align-items: center;">
                            <img class="imageSty" :src="imageUrltemporary.webupurl" />
                        </div>
                        <el-upload
                        :action="uploadImagesUrl"
                        list-type="picture-card"
                        :before-upload="beforeUpload"
                        :on-success="handle_success"
                        :headers="headers"
                        ref="uploadRef"
                        :auto-upload="true">
                            <i slot="default" class="el-icon-plus"></i>
                            <div slot="file" slot-scope="{file}">
                            <img
                                class="el-upload-list__item-thumbnail"
                                :src="file.url" alt=""
                            >
                            <span class="el-upload-list__item-actions">
                                <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleRemove(file)"
                                >
                                <i class="el-icon-delete"></i>
                                </span>
                            </span>
                            </div>
                        </el-upload>
                    </div>
                </el-form-item>
                <!-- <el-form-item label='标签:'>
                    <el-select v-model="filters.labelType" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in labelTypeArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button>
            </div>
        </el-dialog>
        <!-- 查看 -->
        <el-dialog
            title="查看"
            :visible.sync="viewStuDireDialog"
            v-model="viewStuDireDialog"
            :close-on-click-modal="false"
        >
            <div style="margin-bottom: 10px;line-height: 25px;">
                <div>姓名：{{stuDireName}}</div>
                <div>手机号：{{stuDirePhone}}</div>
            </div>
            <el-table
                v-loading='stuDireLoading'
                :data="stuDireData"
                border
                style="width: 100%"
                highlight-current-row
                :cell-style="rowClass"
            >
                <el-table-column type='index' label='序号' width='60' align="center" :index='indexMethod'></el-table-column>
                <el-table-column prop="Status" label="状态" min-width="100" align="center"></el-table-column>
                <el-table-column prop='AgencyName' label='机构名' min-width="100" align="center"></el-table-column>
                <el-table-column prop='StationName' label='岗位' min-width="100" align="center"></el-table-column>
                <el-table-column prop='TradeName' label='行业' min-width="100" align="center"></el-table-column>
                <el-table-column prop="Date" label="日期" min-width="100" align="center"></el-table-column>
            </el-table>
            <el-pagination
                @size-change="stuDireSizeChange"
                @current-change="stuDireCurrentChange"
                :current-page="stuDirePages.PageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="stuDirePages.PageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="stuDirePages.DataCount"
                class='pageBar'>
            </el-pagination>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="viewStuDireDialog = false">取消</el-button>
                <!-- <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button> -->
            </div>
        </el-dialog>
  </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import {
	getStudentExamineeIdentityListPage,
	modifyStudentExamineeIdentity,
	getSocialUnitListPage,
	getStudentLearnRecordPageList,
	getAreaDictionaryList,
	getProjectList
} from '@/api/api'
import { validPhone,valididentityCard } from "../../../util/validate";
import api from '@/api'
export default {
    components:{Toolbar},
    data(){
        //验证手机号
        let isPhoneNumberNum = (rule, value, callback) => {
            if (!validPhone(value)) {
                return callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        //验证身份证号
        let isidentityCardNumberNum = (rule, value, callback) => {
            if (!valididentityCard(value)) {
                return callback(new Error("请输入正确的身份证号"));
            } else {
                callback();
            }
        };
        return {
	        projectDialog:false,
            ListLoading:true,
            tableData:[],
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            filters:{
                condition:'',
                unitName: '',
                selecAreaArr: [],
	              projectId:undefined,
            },
	          projectList:[],
            currentRow:'',
            buttonList:[],
            listLoading:false,
            // 编辑
            isAddOrEdit: false,
            addFormDialog: false,
            addLoading: false,
            imgsizePass: true,
            uploadImagesUrl: '',
            BackgroundWebImgUrl: '',
            dialogForm: {
                name: '',
                phone: '',
                userCard: '',
                userSex: '',
                socialUnitId: '',
                unitAddress: '-',
                socialCreditNumber: '',
                unitName: ''
                // labelType: '',
            },
            imageUrlDataStamp: '',
            imageUrltemporary: '',
            dialogFormRules:{
                name:[{required:true,message:'请输入姓名',trigger:'blur'}],
                phone:[
                    {required:true,message:'请输入手机号',trigger:'blur'},
                    { validator: isPhoneNumberNum },
                ],
                userCard:[
                    {required:true,message:'请输入身份证号',trigger:'blur'},
                    { validator: isidentityCardNumberNum },
                ],
                socialUnitId:[{required:true,message:'请输入单位名称',trigger:'blur'}],
            },
            fileList:[],
            SocialUnitList:[],
            sexArr: [
                {label:'男',value:1},
                {label:'女',value:0}
            ],
            labelTypeArr: [
                {label:'广东信用',value:1},
                {label:'其他',value:2}
            ],
            dataSourceArr: [
                {label:'Android',value:1},
                {label:'IOS',value:2},
                {label:'PC',value:3},
                {label:'MAC',value:4},
            ],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            // 学员流向查看
            viewStuDireDialog: false,
            stuDireLoading: false,
            stuDireName: "",
            stuDirePhone: "",
            stuDireData: [],
            stuDirectionStr: "",
            stuDirePages: {
                PageIndex: 1,
                PageSize: 20,
                DataCount: 0
            },
            // 地区级联选择
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad (node, resolve) {
                    var level = false
                    if(node.value > 0) {
                        var params = {
                            parentId: node.value,
                            // level: 2,
                        }
                        level = true
                    } else {
                        var params = {
                            parentId: JSON.parse(window.localStorage.user).CityID ? JSON.parse(window.localStorage.user).CityID : 1919,
                            // level: 1,
                        }
                        if(JSON.parse(window.localStorage.user).CityID) {
                            level = true
                        } else {
                            level = false
                        }
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var nodes = res.data.Response
                            nodes.forEach(item => {
                                item.value = item.ID
                                item.label = item.Name
                                item.leaf = level
                            })
                            resolve(nodes);
                        }
                    })
                }
            },
            isShowAreaSearch: !JSON.parse(window.localStorage.user).RegionID,
        }
    },
    methods:{
			//获取所有项目列表
	    getProjectList(){
		    let params = {
		    }
		    getProjectList(params).then(res => {
			    if(res.data.Success){
				    this.projectList = res.data.Response
			    }else{
				    this.$message.error(res.data.Message)
			    }
		    })
	    },
        // 表格表头样式
        headClass () {
            return 'height:20px!important;text-align:center;'
        },
        // 单元格样式
        rowClass () {
            return 'padding:8px 0!important;text-align:center;'
        },
        // 分页
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        stuDireSizeChange(value){
            this.stuDirePages.PageSize = value
            this.getStuDireDataFun()
        },
        stuDireCurrentChange(value){
            this.stuDirePages.PageIndex = value
            this.getStuDireDataFun()
        },
        // 页面数据
        getData(){
            this.listLoading = true
            var params = {
								projectId:this.filters.projectId,
                keyWord:this.filters.condition,
                unitName:this.filters.unitName,
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize
            }
            if(JSON.parse(window.localStorage.user).RegionID) {
                params.cityID = JSON.parse(window.localStorage.user).CityID
                params.regionID = JSON.parse(window.localStorage.user).RegionID
            } else if(JSON.parse(window.localStorage.user).CityID) {
                params.cityID = JSON.parse(window.localStorage.user).CityID
                if(this.filters.selecAreaArr.length > 0) {
                    params.regionID = this.filters.selecAreaArr[0]
                }
            } else {
                if(this.filters.selecAreaArr.length > 0) {
                    params.cityID = this.filters.selecAreaArr[0]
                }
                if(this.filters.selecAreaArr.length > 1) {
                    params.regionID = this.filters.selecAreaArr[1]
                }
            }
            getStudentExamineeIdentityListPage(params).then(res => {
                if(res.data.Success){
                    this.listLoading = false
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                }else{
                    this.listLoading = false
                    this.$message.error(res.data.Message)
                }
            })
        },
        // 获取当前行数据
        currentChange(row){
            if(row){
                this.currentRow = row
            }
        },
        // 筛选框内容清空重新请求数据
        clearContent(){
            this.getData()
        },
        // 查询事件
        getStudentExamineeidentityListPage(){
            this.pages.PageIndex = 1
            this.getData()
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 编辑
        handleEdit() {
            if(this.currentRow) {
                this.addFormDialog = true
                this.isAddOrEdit = false
                this.addLoading = false
                this.dialogForm.name = this.currentRow.Name
                this.dialogForm.phone = this.currentRow.PhoneNumber
                this.dialogForm.userCard = this.currentRow.CardNo
                this.dialogForm.userSex = this.currentRow.Gender
                this.dialogForm.unitName = this.currentRow.UnitName
                this.dialogForm.socialCreditNumber = this.currentRow.SocialCreditNumber
                // this.dialogForm.unitAddress = this.currentRow.Address
                // this.dialogForm.labelType = ''
                if(this.currentRow.HeadPhotoUrl) {
                    this.imageUrltemporary = {}
                    this.imageUrltemporary.webupurl = this.currentRow.HeadPhotoUrl
                }
                this.imageUrlDataStamp = ''
                this.getSocialUnitFun(this.currentRow.SocialUnitId,true)
            } else {
                this.$message.warning('请选择要编辑项！')
            }
        },
        // 编辑保存
        addSubmit() {
            var params = {
                Id: this.currentRow.Id,
                Name: this.dialogForm.name,
                PhoneNumber: this.dialogForm.phone,
                CardNo: this.dialogForm.userCard,
                Gender: this.dialogForm.userSex,
                SocialUnitId: this.dialogForm.socialUnitId,
                PastUnitId: this.currentRow.SocialUnitId == this.dialogForm.socialUnitId ? this.currentRow.SocialUnitId : this.currentRow.SocialUnitId,
                HeadPhotoUrl: this.imageUrlDataStamp.webupurl?this.imageUrlDataStamp.webupurl:this.imageUrltemporary.webupurl,
            }
            this.addLoading = true
            modifyStudentExamineeIdentity(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.$message.success(result.Message)
                    this.getData()
                    this.addFormDialog = false
                } else {
                    this.$message.error(result.Message)
                }
                this.addLoading = false
            })
        },
        // 编辑dialog关闭
        dialogCloseFun() {
            this.$refs['uploadRef'].clearFiles()
            var imageHtml = document.getElementsByClassName('el-upload--picture-card')[0]
            imageHtml.style.cssText = 'display: block;'
        },
        // 图片上传之前函数
        beforeUpload(file) {
            var _this = this
            const isLt2M = file.size / 1024 / 1024 > 0.81;
            const isLt30K = file.size / 1024 / 1024 < 0.039;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {} else {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传图片大小不能超过 800KB!');
                return false
            }
            if (!isLt30K === false) {
                this.$message.error('上传图片须大于或等于40KB!');
                return false
            }
            // const isSize = new Promise(function(resolve,reject) {
                let width = 413
                let height = 626
                let _URL = window.URL || window.webkitURL
                let img = new Image()
                img.onload = () => {
                    let valid = img.width >= width && img.height >= height
                    if(!valid){
                        _this.imgsizePass = false
                        return false
                    }else{
                        _this.imgsizePass = true
                    }
                }
                img.src = _URL.createObjectURL(file)
            // })
        },
        // 图片上传成功时的函数
        handle_success(res) {
            if(this.imgsizePass) {
                if(res.Success) {
                    this.$message.success('图片上传成功')
                    this.imageUrlDataStamp = res.Response
                    this.BackgroundWebImgUrl = res.Response.weburl
                    var imageHtml = document.getElementsByClassName('el-upload--picture-card')[0]
                    imageHtml.style.cssText = 'display: none;'
                }else{
                    this.$message.error(res.Message)
                    var imageHtmlRemo = document.getElementsByClassName('el-upload-list el-upload-list--picture-card')[0]
                    var pObjs = imageHtmlRemo.childNodes;
                    for (var i = pObjs.length - 1; i >= 0; i--) {
                        imageHtmlRemo.removeChild(pObjs[i]);
                    }
                }
            }else{
                var imageHtmlRemo = document.getElementsByClassName('el-upload-list el-upload-list--picture-card')[0]
                var pObjs = imageHtmlRemo.childNodes;
                for (var i = pObjs.length - 1; i >= 0; i--) {
                    imageHtmlRemo.removeChild(pObjs[i]);
                }
                this.$message.error('上传图片尺寸须大于413*626像素!')
            }
		},
        // 图片删除函数
        handleRemove(file, fileList) {
            this.imageUrlDataStamp = ''
            this.BackgroundWebImgUrl = ''
            this.$message.success('图片删除成功')
            var imageHtml = document.getElementsByClassName('el-upload--picture-card')[0]
            imageHtml.style.cssText = 'display: block;'
            var imageHtmlRemo = document.getElementsByClassName('el-upload-list__item is-success')[0]
            imageHtmlRemo.parentNode.removeChild(imageHtmlRemo)
        },
        // 获取社会单位
        getSocialUnitFun(e,status) {
            var params = {
                pageIndex: 1,
                pageSize: 20,
            }
            if(status) {
                params.socialUnitId = e
            } else {
                params.socialUnitName = e
            }
            getSocialUnitListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.SocialUnitList = result.Response.Data
                    setTimeout(() => {
                        if(this.currentRow.UnitName) {
                            this.SocialUnitList.forEach(item => {
                                if(item.UnitName == this.currentRow.UnitName) {
                                    this.dialogForm.socialUnitId = item.Id
                                }
                            });
                        }
                    })
                }
            })
        },
        // 查看学员流向
        viewStuDirection(val) {
            this.viewStuDireDialog = true
            this.stuDireData = []
            this.stuDireName = val.Name
            this.stuDirePhone = val.PhoneNumber
            this.stuDirePages.PageIndex = 1
            this.stuDirectionStr = val
            this.getStuDireDataFun()
        },
        getStuDireDataFun() {
            var params = {
                studentAccountId: this.stuDirectionStr.StudentExamineeAccountID,
                pageIndex: this.stuDirePages.PageIndex,
                pageSize: this.stuDirePages.PageSize,
            }
            this.stuDireLoading = true
            getStudentLearnRecordPageList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.stuDireData = result.Response.Data
                    this.stuDirePages.DataCount = result.Response.DataCount
                }
                this.stuDireLoading = false
            })
        },
        searchFun(val) {
            this.getSocialUnitFun(val)
        },
        getToken() {
            return localStorage.getItem("Token")
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.PageIndex
            var pageSize = this.pages.PageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
    },
    computed: {
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        }
    },
    watch: {
        'dialogForm.socialUnitID': function(val) {
            this.SocialUnitList.forEach(item => {
                if(item.ID == val){
                    this.dialogForm.unitAddress = item.Address
                    this.dialogForm.socialCreditNumber = item.SocialCreditNumber
                }
            })
        },
    },
    created(){
	    const router = JSON.parse(window.localStorage.getItem('router'))
	    if (router[0].name === '首页'){
		    this.projectDialog= true;
	    }
			this.getProjectList()
        this.uploadImagesUrl = api.uploadImagesUrl
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getData()
        // this.getSocialUnitFun()
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
    .imageSty {
        width: auto;
        height: auto;
        max-width: 150px;
        max-height: 150px;
    }
</style>
